const local_url = "http://localhost:5000/api/v1";
const server_url = "http://208.87.130.22:5000/api/v1";
// const ngrok = 'http://3d8d-103-21-53-250.ngrok.io/api/v1';
//const ngrok = 'http://208.87.130.22:5000/api/v1';
//const ngrok = "http://208.87.130.22:5000/api/v1";

//const ngrok = "https://login.serpana.com/api/v1";
const ngrok = "https://api.fonolive.com/api/v1";
//const ngrok = "http://localhost:5000/api/v1";
//const ngrok = "http://208.87.130.22:9000";

const api = {
  localUrl: "http://localhost:5000",
  server_url: "https://api.fonolive.com", // "https://login.serpana.com", //
  ngrok: "https://api.fonolive.com", //"https://login.serpana.com", //

  login: `${ngrok}/user/login`,
  registerUser: `${ngrok}/user/register`,
  recentLoginData: `${ngrok}/user/recentLogin`,
  editProfile: `${ngrok}/user/editProfile`,
  getProfileData: `${ngrok}/user/getUserDataById`,
  getProfileBasicData: `${ngrok}/user/getUserBasicDataById`,
  addPost: `${ngrok}/feed/addPost`,
  fetchPost: `${ngrok}/feed/fetchPost`,
  fetchPostApp: `${ngrok}/feed/getFetchPostApp`,
  getFetchPostCustom: `${ngrok}/feed/getFetchPostCustom`,
  getFetchPostLanding: `${ngrok}/feed/getFetchPostLanding`,
  getFetchPost: `${ngrok}/feed/getFetchPost`,
  likePost: `${ngrok}/feed/likePost`,
  unlikePost: `${ngrok}/feed/unlikePost`,
  deletePost: `${ngrok}/feed/deletePost`,
  hidePost: `${ngrok}/feed/hidePost`,
  addSharePost: `${ngrok}/feed/addSubPost`, //share post in feed
  fetchComment: `${ngrok}/comment/fetchComment`,
  fetchReply: `${ngrok}/comment/fetchReply`,
  replyComment: `${ngrok}/comment/addReply`,
  fetchPostByUserId: `${ngrok}/feed/fetchPostByUserId`,
  fetchPostCount: `${ngrok}/feed/countFeedPosts`,
  suggesstion: `${ngrok}/user/suggestion`,
  followRequest: `${ngrok}/user/followRequest`,
  addFollower: `${ngrok}/user/add-follower`,
  unfollow: `${ngrok}/user/unfollow`,

  //My Network
  fetchFollowing: `${ngrok}/user/fetchFollowing`,
  fetchFollowingCount: `${ngrok}/user/fetchFollowingCount`,
  blockUser: `${ngrok}/user/blockUser`,

  fetchFollower: `${ngrok}/user/fetchFollower`,
  fetchCategory: `${ngrok}/admin/fetchCategory`,
  createJob: `${ngrok}/job/addPost`,
  fetchJobs: `${ngrok}/job/fetchAllPost`,
  deleteJobs: `${ngrok}/job/deletePostById`,
  fetchJobPostById: `${ngrok}/job/fetchPostById`,
  getAllJobs: `${ngrok}/job/search`,
  recomendation: `${ngrok}/job/recomendations`,
  applyPost: `${ngrok}/job/applyPost`,
  createPdf: `${ngrok}/job/createPdf`,
  fetchPdf: `${ngrok}/job/fetchPdf`,
  fetchResumeByUserId: `${ngrok}/job/fetchResumeByUserId`,
  updateResume: `${ngrok}/job/updateResume`,
  usersJob: `${ngrok}/job/fetchJobByUser`,
  searchByCity: `${ngrok}/job/searchByCity`,
  //Article
  createArticle: `${ngrok}/article/createArticle`,
  fetchArticleByCatId: `${ngrok}/article/fetchArticleByCatId`,
  fetchArticleByUserId: `${ngrok}/article/fetchArticleByUserId`,
  fetchAllArticle: `${ngrok}/article/fetchArticle`,
  searchArticle: `${ngrok}/article/search`,
  fetchArticleById: `${ngrok}/article/fetchArticleById`,
  likeArticle: `${ngrok}/article/likeArticle`,
  unlikeArticle: `${ngrok}/article/unlikeArticle`,
  deleteArticle: `${ngrok}/article/deleteArticleById`,
  popularArticle: `${ngrok}/article/popularArticle`,
  trendingArticle: `${ngrok}/article/trendingArticle`,
  editArticle: `${ngrok}/article/editArticle`,

  createEvent: `${ngrok}/event/createEvent`,
  editEvent: `${ngrok}/event/editEvent`,
  fetchEvent: `${ngrok}/event/fetchEvents`,
  bookEvent: `${ngrok}/event/bookEvent`,
  fetchEventByUserId: `${ngrok}/event/fetchEventByUserId`,
  searchEvent: `${ngrok}/event/search`,
  deleteEvent: `${ngrok}/event/deleteEvent`,
  fetchProducts: `${ngrok}/market/fetchProduct`,
  recomendedProduct: `${ngrok}/market/recomendedProducts`,
  fetchProductById: `${ngrok}/market/fetchProductById`,
  fetchRentalProducts: `${ngrok}/market/fetchProductsRental`,
  fetchRecomendedRentalProducts: `${ngrok}/market/recomendedRentalProducts`,
  addCart: `${ngrok}/cart/addCart`,
  fetchCartById: `${ngrok}/cart/fetchCartById`,
  updateQunatity: `${ngrok}/cart/updateQty`,
  deleteCart: `${ngrok}/cart/deleteCartById`,
  searchProduct: `${ngrok}/market/search`,
  addProperty: `${ngrok}/realState/addProperty`,
  editPropertyById: `${ngrok}/realState/editPropertyById`,
  fetchProperty: `${ngrok}/realState/fetchProperty`,
  fetchRentalProperty: `${ngrok}/realState/fetchRentalProperty`,
  fetchPropertyById: `${ngrok}/realState/fetchPropertyById`,
  addBusiness: `${ngrok}/business/createBusiness`,
  editBusiness: `${ngrok}/business/editBusiness`,
  fetchBusiness: `${ngrok}/business/fetchBusiness`,
  fetchBusinessById: `${ngrok}/business/fetchBusinessById`,
  likeBusiness: `${ngrok}/business/likeBusiness`,
  unlikeBusiness: `${ngrok}/business/unlikeBusiness`,
  reportBusiness: `${ngrok}/business/reportBusiness`,
  deleteBusinessById: `${ngrok}/business/deleteBusinessById`,
  reviewBusiness: `${ngrok}/business/review`,
  createService: `${ngrok}/service/createService`,
  fetchService: `${ngrok}/service/fetchService`,
  fetchServiceById: `${ngrok}/service/fetchServiceById`,
  addBid: `${ngrok}/service/addBid`,
  deleteBidById: `${ngrok}/service/deleteBidById`,
  addWorker: `${ngrok}/service/addWorker`,
  fetchRespondService: `${ngrok}/service/fetchActiveService`,
  fetchServiceByUserId: `${ngrok}/service/fetchServiceByUserId`,
  updateBid: `${ngrok}/service/updateBid`,
  createMileStone: `${ngrok}/service/createMilestone`,
  addReview: `${ngrok}/service/addReview`,
  fetchCompleteServices: `${ngrok}/service/fetchCompleteServices`,

  //User Chat
  coversationList: `${ngrok}/chat/chatList`,
  fetchConversations: `${ngrok}/chat/fetchConversations`,
  seenMsg: `${ngrok}/chat/seen`,
  clearUserChat: `${ngrok}/chat/clearChat`,
  //Dating
  activeDating: `${ngrok}/dating/activeDating`,
  datingForm: `${ngrok}/dating/createForm`,
  getMyMatches: `${ngrok}/dating/fetchMatches`,
  getDatingRequest: `${ngrok}/dating/fetchRequest`,
  removeDating: `${ngrok}/dating/removeDating`,
  likeDating: `${ngrok}/dating/matchRequest`,
  dislikeDating: `${ngrok}/dating/dislikeMatch`,
  acceptDatingRequest: `${ngrok}/dating/addFriends`,
  rejectDatingRequest: `${ngrok}/dating/deleteRequest`,
  datingAddedFriend: `${ngrok}/dating/fetchUserMatches`,
  datingProfileById: `${ngrok}/dating/fetchDatingProfileById`,
  removeDatingMatch: `${ngrok}/dating/removeFriends`,
  fetchDatingChatConversation: `${ngrok}/dating/getConversations`,
  clearDatingChat: `${ngrok}/dating/clearChat`,
  editDatingProfile: `${ngrok}/dating/EditProfile`,
  deleteDatingImage: `${ngrok}/dating/removeImage`,

  addComment: `${ngrok}/comment/addComment`,
  addNotification: `${ngrok}/notify/addNotification`,
  removeToken: `${ngrok}/notify/removeToken`,
  createGroup: `${ngrok}/group/createGroup`,
  userGroup: `${ngrok}/group/fetchUserGroup`,
  memberGroup: `${ngrok}/group/fetchMemberGroup`,
  userAddGroup: `${ngrok}/group/fetchAddedGroup`,
  fetchGroupById: `${ngrok}/group/fetchGroupById`,
  editGroup: `${ngrok}/group/updateGroup`,
  addDeleteGroupMember: `${ngrok}/group/addMember`,
  fetchPublicGroup: `${ngrok}/group/fetchPublicGroup`,
  groupRequest: `${ngrok}/group/groupRequest`,
  fetchGroupConversations: `${ngrok}/group/fetchGroupConversation`,
  getNotification: `${ngrok}/notify/getNotification`,
  forgetPassword: `${ngrok}/user/forgotPassword`,
  resetPassword: `${ngrok}/user/reset-password`,
  checkEmail: `${ngrok}/user/checkEmail`,
  uploadGroupFile: `${ngrok}/group/uploadFile`,
  deleteGroup: `${ngrok}/group/deleteGroup`,
  groupSearch: `${ngrok}/group/searchGroupByKeyword`,
  sharePost: `${ngrok}/feed/sharePost`,
  fetchPostById: `${ngrok}/feed/fetchPostById`,
  // fetchPdfByUserId: `${ngrok}/job/fetchPdfByUserId`,
  uploadResume: `${ngrok}/job/uploadResume`,
  fetchPdfByUserId: `${ngrok}/job/fetchPdfByUserId`,
  fetchResumeById: `${ngrok}/job/fetchResumeById`,

  //MarketPlace
  createClassifieds: `${ngrok}/product/addClassRentals`,
  editClassifieds: `${ngrok}/product/editClassRentals`,
  mainCategory: `${ngrok}/category/getMainCategoryList`,
  subCategory: `${ngrok}/category/getAllSubCategory`,
  subCategoryList: `${ngrok}/category/getSubCategoryList`,
  childCategory: `${ngrok}/category/getSubChildCategoryList`,
  shopProductsList: `${ngrok}/product/getAllWebProductList`,
  productDetails: `${ngrok}/product/getWebProductById`,
  subscriptionDetails: `${ngrok}/product/getWebSubscriptionById`,
  rateProduct: `${ngrok}/product/addRating`,
  removerWishListFromCard: `${ngrok}/wishlist/removeWishlistById`,
  //WishList

  addWishlist: `${ngrok}/wishlist/addWishlist`,
  removeWishlist: `${ngrok}/wishlist/removeWishlist`,
  fetchWishlistById: `${ngrok}/wishlist/fetchProductById`,
  deleteWishlistById: `${ngrok}/wishlist/deleteProductById`,
  addWishlist: `${ngrok}/wishlist/addWishlist`,
  fetchWishList: `${ngrok}/wishlist/fetchProductById`,
  removeWishList: `${ngrok}/wishlist/removeWishlist`,
  removeAllWishList: `${ngrok}/wishlist/removeAllWishlist`,

  //Cart
  addToCart: `${ngrok}/cart/addCart`,
  fetchCartProduct: `${ngrok}/cart/fetchCartById`,
  deleteCartProduct: `${ngrok}/cart/deleteCartById`,
  updateCartProductQty: `${ngrok}/cart/updateQty`,
  addAllProductToCart: `${ngrok}/cart/addAllProduct`,
  cartCount: `${ngrok}/cart/cartCount`,

  //AdCart
  addToAdCart: `${ngrok}/adcart/addCart`,
  fetchAdCartProduct: `${ngrok}/adcart/fetchCartById`,
  deleteAdCartProduct: `${ngrok}/adcart/deleteCartById`,
  updateAdCartProductQty: `${ngrok}/adcart/updateQty`,
  addAllProductToAdCart: `${ngrok}/adcart/addAllProduct`,
  adCartCount: `${ngrok}/adcart/cartCount`,

  //Order
  placeOrder: `${ngrok}/order/orderCreate`,
  orderList: `${ngrok}/order/listById`,
  createOrder: `${ngrok}/payment/createOrder`,
  captureOrder: `${ngrok}/payment/captureOrder`,
  createSubscription: `${ngrok}/payment/createSubscription`,
  captureSubscription: `${ngrok}/payment/captureSubscription`,
  createPaymentIntent: `${ngrok}/payment/paymentIntent`,
  retrievePaymentIntent: `${ngrok}/payment/retrievePaymentIntent`,
  createPaymentIntentSubscription: `${ngrok}/payment/paymentIntentSubscription`,
  retrievePaymentIntentSubscription: `${ngrok}/payment/retrievePaymentIntentSubscription`,
  createPaymentIntentTest: `${ngrok}/payment/paymentIntentTest`,

  //AdOrder
  placeAdOrder: `${ngrok}/adorder/orderCreate`,
  orderAdList: `${ngrok}/adorder/listById`,
  createAdPaymentIntent: `${ngrok}/adpayment/paymentIntent`,
  retrieveAdPaymentIntent: `${ngrok}/adpayment/retrievePaymentIntent`,
  createAdPaymentIntentTest: `${ngrok}/adpayment/paymentIntentTest`,

  //Setting Apis
  settingLogIn: `${ngrok}/user/fetchDeviceInfo`,
  removeDevice: `${ngrok}/user/removeDevice`,
  blockdevice: `${ngrok}/user/blockDevice`,
  deactivateAccount: `${ngrok}/user/deactivateAcc`,
  deleteAccount: `${ngrok}/user/deleteAcc`,

  //Global Feed Data -- group, page, vendor store sources
  addGlobalPost: `${ngrok}/globalFeed/addPost`,
  fetchGlobalPost: `${ngrok}/globalFeed/fetchPost`,
  likeGlobalPost: `${ngrok}/globalFeed/likePost`,
  unlikeGlobalPost: `${ngrok}/globalFeed/unlikePost`,
  deleteGlobalPost: `${ngrok}/globalFeed/deletePost`,
  hideGlobalPost: `${ngrok}/globalFeed/hidePost`,
  addGlobalSharePost: `${ngrok}/globalFeed/addSubPost`, //share post in feed
  addGlobalComment: `${ngrok}/globalComment/addComment`,
  fetchGlobalComment: `${ngrok}/globalComment/fetchComment`,
  fetchGlobalReply: `${ngrok}/globalComment/fetchReply`,
  replyGlobalComment: `${ngrok}/globalComment/addReply`,
  fetchPostBySourceId: `${ngrok}/globalFeed/fetchPostBySourceId`,
  getGlobalDataById: `${ngrok}/globalFeed/getGlobalDataById`,
  fetchGlobalPostById: `${ngrok}/globalFeed/fetchPostById`,

  //Auth
  verifyEmailOtp: `${ngrok}/user/verifyOtp`,
  resendEmailOtp: `${ngrok}/user/resendOtp`,
  verifyId: `${ngrok}/user/checkUser`,
  checkUserPhone: `${ngrok}/user/checkUserPhone`,
  verifyPhone: `${ngrok}/user/verifyPhone`,
  fetchDatingRecentChat: `${ngrok}/dating/chatList`,

  //ElasticSearch
  searchAll: `${ngrok}/search/searchAll`,
  searchByCategory: `${ngrok}/search/searchByCategory`,
  searchFilter: `${ngrok}/search/searchFilter`,
  searchIndex: `${ngrok}/search/searchIndex`,
  searchJobs: `${ngrok}/search/searchJobs`,
  getIndexData: `${ngrok}/search/getIndexData`,
  getDataTimeDesc: `${ngrok}/search/getDataTimeDesc`,
  getDataTimeUpdated: `${ngrok}/search/getDataTimeUpdated`,
  searchMarketplace: `${ngrok}/search/searchMarketplace`,
  searchMarketByMarket: `${ngrok}/search/searchMarketByMarket`,
  getMarketByMarket: `${ngrok}/search/getMarketByMarket`,
  getRandByMarket: `${ngrok}/search/getRandByMarket`,
  getMarketByUserId: `${ngrok}/search/getMarketByUserId`,
  getMarketSubCat: `${ngrok}/search/getMarketSubCat`,
  getMarketChildCat: `${ngrok}/search/getMarketChildCat`,
  getMarketCat: `${ngrok}/search/getMarketCat`,
  getIndexByUserId: `${ngrok}/search/getIndexByUserId`,
  checkSpam: `${ngrok}/search/checkSpam`,
  getVCScore: `${ngrok}/search/getVCScore`,
};

export default api;
